export class Menu {
    constructor(_el) {
        this.menu = _el;
        this.body = $('html, body');
        this.window = $(window);
        this.showBtn = $('.header__menu-btn');
        this.links = this.menu.find('a');

        this.onEvents();
    }

    onEvents() {
        const that = this;

        that.showBtn.on({
            click: function () {
                that.openMenu($(this));
            }
        });

        that.window.on({
            resize: function () {
                that.resetStyle();
            }
        });

        this.links.on({
            click: function (e) {
                e.preventDefault();

                if (that.menu.hasClass('opened')) {
                    that.resetStyle();
                }

                that.body.animate({
                    scrollTop: $($(this).attr('href')).offset().top
                }, 600);
            }
        });
    }

    openMenu(elem) {
        const curItem = elem;

        if (curItem.hasClass('opened')) {
            this.resetStyle()
        } else {

            curItem.addClass('opened');
            this.menu.addClass('opened');

            this.body.css({
                'overflow': 'hidden'
            });
        }
    }

    resetStyle() {
        this.showBtn.removeClass('opened');
        this.menu.removeClass('opened');
        this.body.css({'overflow': 'visible'});
    }
}

$(function () {
    $.each($('.header__menu'), function () {
        new Menu($(this));
    });
});
