require("@fancyapps/fancybox");

export class HowItWorkSlider {
    constructor(_el) {
        this.wrapper = _el;
        this.sliderControls = this.wrapper.find('.slider-controls');
        this.swiper = null;
        this.slider = this.wrapper.find('.swiper-container');
        this.slides = this.slider.find('.swiper-slide');
        this.nextBtn = this.sliderControls.find('.slider-controls__btn_right')[0];
        this.prevBtn = this.sliderControls.find('.slider-controls__btn_left')[0];
        this.pagination = this.wrapper.find('.slider__pagination')[0];
        this.adminBtn = this.wrapper.find('.admin');
        this.employeeBtn = this.wrapper.find('.employee');
        this.window = $(window);
        this.fancyBox = null;

        this.initSwiper();
        this.setActiveBtn();
        this.onEvents();
    }

    onEvents() {
        this.window.on({
            resize: () => {
                const windowWidth = this.window.width();
                const body = $('body');

                if (windowWidth < 1150 && $('.fancybox-active').length) {
                    body.removeClass('fancybox-active');
                    body.removeClass('compensate-for-scrollbar');
                    $('.fancybox-container').remove()
                }
            }
        });
        this.adminBtn.on({
            click: () => {
                this.swiper.slideTo(this.slider.find('.admin').index());
            }
        });

        this.employeeBtn.on({
            click: () => {
                this.swiper.slideTo(this.slider.find('.employee').index());
            }
        });
    }

    setActiveBtn() {
        const activeSlide = this.slides.eq(this.swiper.activeIndex);

        if (activeSlide.hasClass('employee')) {
            this.employeeBtn.addClass('active');
            this.adminBtn.removeClass('active');
        } else {
            this.adminBtn.addClass('active');
            this.employeeBtn.removeClass('active');
        }
    }

    initSwiper() {
        this.swiper = new Swiper(this.slider[0], {
            pagination: {
                el: this.pagination,
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                }
            },
            navigation: {
                nextEl: this.nextBtn,
                prevEl: this.prevBtn
            }
        });

        this.swiper.on('slideChange', () => {
            this.setActiveBtn();
        });

        $('[data-fancybox="gallery"]').fancybox({
            scrollOutside: true,
            thumbs: {
                autoStart: true,
                axis: 'x'
            }
        });
    }
}

$(function () {
    $.each($('.how-it-work'), function () {
        new HowItWorkSlider($(this));
    });
});
